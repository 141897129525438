// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_pC d_fl d_bC d_dr";
export var alignLeft = "n_pD d_fl d_bC d_dr";
export var alignDiscCenter = "n_pF d_fm d_bz d_ds";
export var alignCenter = "n_bL d_fm d_bz d_ds";
export var alignDiscRight = "n_pG d_fn d_bD d_dt";
export var alignRight = "n_pH d_fn d_bD d_dt";
export var footerContainer = "n_pJ d_dS d_bS";
export var footerContainerFull = "n_pK d_dQ d_bS";
export var footerHeader = "n_kc d_kc";
export var footerTextWrapper = "n_pL d_s";
export var footerDisclaimerWrapper = "n_kk d_kk d_cg";
export var badgeWrapper = "n_pM d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "n_kl d_kl d_bw";
export var footerDisclaimerLeft = "n_km d_km d_bw";
export var verticalTop = "n_pN d_bw d_bF d_bK d_bH";
export var firstWide = "n_pP";
export var disclaimer = "n_pQ";
export var socialDisclaimer = "n_pR";
export var left = "n_pS";
export var wide = "n_pT d_cv";
export var right = "n_pV d_bG";
export var line = "n_fg d_fh d_cr";
export var badgeDisclaimer = "n_pW d_by d_bL d_bF";
export var badgeContainer = "n_pX d_bw d_bD d_bL";
export var badge = "n_pY";
export var padding = "n_pZ d_c4";
export var end = "n_p0 d_bD";
export var linkWrapper = "n_p1 d_dx";
export var link = "n_mF d_dx";
export var colWrapper = "n_p2 d_ct";
export var media = "n_p3 d_bt d_dv";
export var itemRight = "n_p4";
export var itemLeft = "n_p5";
export var itemCenter = "n_p6";
export var exceptionWeight = "n_p7 q_rg";