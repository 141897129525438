// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "m_pr d_bz";
export var storyRowWrapper = "m_hv d_hv d_bG";
export var storyLeftWrapper = "m_ps d_bw d_bL";
export var storyWrapperFull = "m_pt d_cz";
export var storyWrapperFullLeft = "m_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "m_mC d_hw";
export var storyLeftWrapperCenter = "m_pv d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "m_pw d_hC";
export var storyHeader = "m_px d_hB d_s d_cp";
export var storyHeaderCenter = "m_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "m_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "m_py d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "m_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "m_pz d_fc d_W";
export var imageWrapperFull = "m_pB d_s d_D d_bb d_W";